import { POST_SEGURIDAD_COMITENTES, HEADERS } from 'constants/endpoints';
import axios from 'axios';
import Swal from 'sweetalert2';

export const postSeguridad = (data) => {
	const {codUsu, clave} = data;
	console.log({data: data});

	return new Promise((resolve, reject) => {
		return axios({
			url: POST_SEGURIDAD_COMITENTES,
			method: 'POST',
			headers: HEADERS,
			data: data
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				if(json.results === true){
					console.log({jsonDevuelve: json.data});
					localStorage.setItem('codEnt', json.data.codEnt);
					localStorage.setItem('Usuario_Logueado', json.data.usuario);
					localStorage.setItem('Delegacion', json.data.entidad);
					return resolve({ data: json.results });
				}else{
					console.log(json.errorMessage);
					Alerta.fire(
                        "Error!",
                        `${json.errorMessage}`,
                        "warning"
                      );
				}
				console.log({json});
				return resolve({ data: json.results });
					
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};
