import React, { useState } from 'react';
import { Button, Tooltip} from '@mui/material';
import { useHistory, useParams, useLocation} from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import MiCredencialInternaAux from 'components/miCredencialInternaAux';


const MiCredencialInterna = () => {
  let history = useHistory();
  const [imagenSeleccionada, setImagenSeleccionada] = useState(0); // Inicia con la primera imagen
 
  const location = useLocation();
  const imagenes = location.state?.data || false;
 //console.log(imagenes)
  //const { imageId } = useParams();
  //const image = images.find(img => img.id === parseInt(imageId));
  
  if (!imagenes) {
    return <MiCredencialInternaAux />
    //<div>Credencial no encontrada</div>;
  }
  else
  {
    
   // const imagenes = [
   //   image.frente,
   //   image.dorso
   // ];

   return (
    <div style={{
      display: "flex",
      flex: "1"
      //border: "2px solid #000000"
      
      //padding: "40px 25px",
      //marginTop: "50px"
    }}>
      
      <div style={{
      display: "flex",
      flex: "1",
      alignItems: "center",
      justifyContent: "center",      //border: "2px solid #FF0000"
      //padding: "40px 25px",
      //marginTop: "50px"
    }}>

    
      <div style={{ padding:'10px 0px ', position: 'relative' , display:'flex', height:'100vh' ,maxHeight: '-webkit-fill-available'}}>
      <Carousel renderThumbs={() => {}} style={{ maxWidth: '100vw', height: '100vh'}}>
        <img src={imagenes[0]} alt="Imagen seleccionada" 
        style={{objectFit:'contain' , height:'80vh'}} 
        />
        <img src={imagenes[1]} alt="Imagen seleccionada" 
        style={{objectFit:'contain' , height:'80vh'}} 
        />

      </Carousel>
        </div>


      
      </div>
    </div>
  );
  }
};

export default MiCredencialInterna;