//Layouts
import PrincipalLayout from 'layouts/principal';
import PrincipalCredencialLayout from 'layouts/principalCredencial';
import UsuariosNoValidos from 'pages/usuarioNoValido';
import MiCredencial from 'pages/miCredencial';  
import ImagesComponent from 'pages/ImagesComponent';
import MiCredencialPdf from 'pages/miCredencialPdf';
import MiCredencialExterna from 'pages/miCredencialExterna';
import MiCredencialInterna from 'components/miCredencialInterna';
import CredencialConsejo from 'pages/CredencialConsejo';
import PruebaConexion from 'pages/PruebaConexion';
//Pages
import Home from 'pages/Home';

import NotFound from 'pages/NotFound';



//Classes
class ROUT {
    constructor(path, display, layout, page,classNames) {
      this.PATH = path;
      this.DISPLAY = display;
      this.LAYOUT = layout;
      this.PAGE = page;
      this.CLASS_NAME = classNames;
    }
}

//constantes internas
const USUARIO_NO_VALIDO = new ROUT('/usuarioNoValido','USUARIO_NO_VALIDO',PrincipalLayout, UsuariosNoValidos, 'usuarioNoValido');
const NOT_FOUND = new ROUT('*','No Disponible', PrincipalLayout, NotFound,'not-found');
const QR = new ROUT('/Qr/:imageId','Mi_QR',PrincipalLayout, MiCredencial , 'Qr/:imageId');
const MICREDENCIAL_PRINCIPAL = new ROUT('/CredencialConsejo','MICREDENCIAL_PRINCIPAL',PrincipalLayout, CredencialConsejo , 'CredencialConsejo');
//const MICREDENCIAL_PRINCIPAL = new ROUT('/CredencialConsejo/:imageId','MICREDENCIAL_PRINCIPAL',PrincipalLayout, CredencialConsejo , 'CredencialConsejo/:imageId');
const CREDENCIAL_PDF = new ROUT('/CredencialPdf/:imageId','Mi_QR',PrincipalLayout, MiCredencialPdf , 'CredencialPdf/:imageId');
const CREDENCIAL_EXTERNA = new ROUT('/Credenciales/:imageId','CREDENCIAL_EXTERNA',PrincipalCredencialLayout, MiCredencialExterna , 'Credenciales/:imageId');
const CREDENCIAL_INTERNA = new ROUT('/MiCredencialInterna','CREDENCIAL_INTERNA',PrincipalCredencialLayout, MiCredencialInterna , 'MiCredencialInterna');
//const CREDENCIAL_INTERNA = new ROUT('/MiCredencial/MiCredencialConsulta,'CREDENCIAL_INTERNA',PrincipalCredencialLayout, MiCredencialInterna , 'MiCredencialInterna/:imageId');
const MI_CREDENCIAL = new ROUT('/CREDENCIAL/:imageId','CREDENCIAL',PrincipalLayout, ImagesComponent , 'credencial/:imageId');
const PRUEBACONEXION = new ROUT('/PruebaConexion/:imageId','PRUEBACONEXION',PrincipalCredencialLayout, PruebaConexion , 'PruebaConexion/:imageId');

//Exports
export const ROUTES = Object.freeze({
    //HOME,
    NOT_FOUND,
    USUARIO_NO_VALIDO,
    MICREDENCIAL_PRINCIPAL,
    QR,
    CREDENCIAL_EXTERNA,
    CREDENCIAL_INTERNA,
    MI_CREDENCIAL,
    PRUEBACONEXION,
    LIST: [
        //HOME,
        MICREDENCIAL_PRINCIPAL,
        QR,
        CREDENCIAL_PDF,
        MI_CREDENCIAL,
        CREDENCIAL_EXTERNA,
        CREDENCIAL_INTERNA,
        USUARIO_NO_VALIDO,
        PRUEBACONEXION,
        NOT_FOUND //Dejar siempre en ultimo lugar!!!
    ]
})