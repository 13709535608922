import { GET_FILTRO_COMITENTE, GET_FILTRO_CUIT, POST_COMITENTE, GET_MOSTRAR_STRING_COMITENTE, HEADERS } from 'constants/endpoints';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from "react-router";


export const getComitente = (data) => {
	const {nroActuacion, nroLegajo, fechaDesde, fechaHasta, decodeCodUsu, decodeClave, codEntRecuperado} = data;
	
	const res = {
		"nroActuacion": nroActuacion,
        "nroLegajo": nroLegajo,
		"FechaDesde": fechaDesde,
		"FechaHasta": fechaHasta,
		"EncryptedUser": decodeCodUsu,
		"EncryptedPassword": decodeClave,
		"codEnt": codEntRecuperado
	}
	console.log({res});
	return new Promise((resolve, reject) => {
		return axios({
			url: GET_FILTRO_COMITENTE,
			method: 'GET',
			headers: HEADERS,	
			params: res	
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				if(json.results){
					console.log('hola');
					return resolve({ data: json.dataList });
				}else{
					Alerta.fire(
                        "Mensaje!",
                        `${json.errorMessage}`,
                        "warning"
                      );

					  return resolve({ data: [] });
				}
				
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};

export const getCuitComitente = (data) => {

	const {cuit, idActuacion} = data;
	
	const res = {
		"cuit": cuit,
		"idActuacion": idActuacion
	}

	console.log({res});
	//  console.log('esto es lo que viene del data');
	// console.log(data);
	// console.log('es lo que va a pintar el res');
	// console.log(res);
	return new Promise((resolve, reject) => {
		return axios({
			url: GET_FILTRO_CUIT,
			method: 'GET',
			headers: HEADERS,	
			params: res	
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				//console.log({aquiValidando: json});
				console.log('hola2', json.results);
				if(json.results){
					return resolve({ data: json.data});
				}else{
					Alerta.fire(
                        "Mensaje!",
                        `No hay resultados en la búsqueda`,
                        "warning"
                      );
					  //return resolve({ data: json.data }); 
				}
				
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};

export const postGenerarStringComitente = (data) => {
	const {idActuacion, tablaPrueba, EncryptedUser, EncryptedPassword} = data;
	console.log({data});
	return new Promise((resolve, reject) => {
		return axios({
			url: GET_MOSTRAR_STRING_COMITENTE,
			method: 'POST',
			headers: HEADERS,	
			data: data	
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				if(json.results === true){
					return resolve({ data: json.data});
				}else{
					Alerta.fire(
                        "Mensaje!",
                        `No hay resultados en la búsqueda`,
                        "warning"
                      );
					  return resolve({ data: json.data }); 
				}
				
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};


export const postComitente = (data) => {
	const {idActuacion, tablaPrueba, EncryptedUser, EncryptedPassword} = data;
	console.log({data});
	
	const res = {
		"idActuacion": idActuacion,
		"ComitentesAASociar": tablaPrueba,
		"EncryptedUser": EncryptedUser,
		"EncryptedPassword": EncryptedPassword,
	}
	//  console.log('esto es lo que viene del data');
	// console.log(data);
	console.log('es lo que va a pintar el res');
	console.log({res});
	return new Promise((resolve, reject) => {
		return axios({
			url: POST_COMITENTE,
			method: 'POST',
			headers: HEADERS,	
			data: data	
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				console.log({json});
				if(json.results === true){		
					Alerta.fire(
                        "Comitentes Asociados!",
                        `Los comitentes han sido asociados con éxito!`,
                        "success"
                      );
				}else{
					console.log(json.errorMessage);
					Alerta.fire(
                        "Error!",
                        `${json.errorMessage}`,
                        "warning"
                      );
				}
				console.log({json});
				return resolve({ data: json.results });
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};