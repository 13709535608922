import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getComitente, getCuitComitente, postGenerarStringComitente, postComitente} from 'app/features/BusquedaComitente/BusquedaComitenteAPI';

const initialState = {
  List: [],
  status: 'idle',
  comitenteSeleccionado: {},
  comitenteAsociar: {},
  dataSearch:{},
  dataSearchCuit:{}
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getComitenteAsync = createAsyncThunk(
  'comitentes/fetchFiltroComitentes',
  async (data) => {
    const response = await getComitente(data);
    console.log({response: response.data});
    return response.data;
  },
);

export const getCuitComitenteAsync = createAsyncThunk(
  'comitentes/fetchFiltroCuitComitentes',
  async (data) => {
    const response = await getCuitComitente(data);
    return response.data;
  },
);

export const postGenerarStringComitenteAsync = createAsyncThunk(
  'comitentes/fetchFiltroStringNuevo',
  async (data) => {
    const response = await postGenerarStringComitente(data);
    console.log({response});
    return response.data;
    
  },
);

export const postComitenteAsync = createAsyncThunk(
  'comitentes/fetchComitentesPost',
  async (data) => {
    const response = await postComitente(data);
    return response.data;
  }
);


export const busquedaComitenteSlice = createSlice({

  name: 'busquedaComitente',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
    setcomitenteSeleccionado: (state, action) => {
      state.comitenteSeleccionado = action.payload;
      console.log({state: action.payload})
    },

    setCuitComitenteSeleccionado: (state, action) => {
      state.comitenteAsociar = action.payload;
    },

    
    setDataSearch: (state, action) => {
      state.dataSearch = action.payload;
    },

    setDataSearchCuit: (state, action) => {
      state.dataSearchCuit = action.payload;
    },
    
    guardarComitenteSeleccionado: (state, action) => {
      
      const comitenteToSave = {
        tablaPrueba:action.payload.tablaPrueba,
        //encryptedUser: action.payload.EncryptedUser,
        //encryptedPassword: action.payload.EncryptedPassword
      };
      if(action.payload?.tablaPrueba.length > 0){
        postComitenteAsync(comitenteToSave);
      }

    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getComitenteAsync.pending, (state) => {
        state.status = 'loading';
        console.log({veamosle: state.status});
      })
      .addCase(getComitenteAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.List = action.payload;
        console.log({payload: action.payload});
        console.log({veamosle: state.setComitenteSeleccionado});
      })
      .addCase(getCuitComitenteAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCuitComitenteAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action);
        console.log('aqui la lista');
        console.log(state.List);
        state.List = action.payload;
        console.log(state.List);
      })
      .addCase(postGenerarStringComitenteAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postGenerarStringComitenteAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action);
        console.log('aqui la lista');
        console.log(state.List);
        state.List = action.payload;
        console.log(state.List);
      })
  },
});

export const { setSearch, setDataSearch, setDataSearchCuit, setComitenteSeleccionado, setCuitComitenteSeleccionado, guardarComitenteSeleccionado} = busquedaComitenteSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.<nameReducer>.<Key_of_state>)`

export const comitenteList = (state) => state.busquedaComitenteReducer.List;
export const cuitComitenteList = (state) => state.busquedaComitenteReducer.List;

export const comitenteStatus = state => state.busquedaComitenteReducer.status;
export const cuitComitenteStatus = state => state.busquedaComitenteReducer.status;

export const filtroComitenteSeleccionado = (state) => state.busquedaComitenteReducer.comitenteSeleccionado;
export const filtroCuitComitenteSeleccionado = (state) => state.busquedaComitenteReducer.comitenteAsociar;

export const comitenteDataSearch = (state) => state.busquedaComitenteReducer.dataSearch;
export const cuitComitenteDataSearch = (state) => state.busquedaComitenteReducer.dataSearchCuit;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default busquedaComitenteSlice.reducer;