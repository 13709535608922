
import React from 'react';

class DownloadPDFButton extends React.Component {
  downloadPDF = async () => {
    try {
      // Aquí rconst response = await fetch('url_de_tu_api');
      const response = await fetch('https://localhost:7168/credencial/pdf2/173762');
      const data = await response.json();
      
      const pdfBlob = new Blob([Uint8Array.from(atob(data.PdfData), c => c.charCodeAt(0))], { type: 'application/pdf' });

      const pdfURL = URL.createObjectURL(pdfBlob);

      // Abre el PDF en una nueva pestaña
      window.open(pdfURL, '_blank');  
      //           const response = await fetch('https://localhost:7168/credencial/pdf2/173762', {
       // method: 'GET',
      //});

      // Verifica si la solicitud fue exitosa
     // if (!response.ok) {
     //   throw new Error(`HTTP error! status: ${response.status}`);
     // }

      // Obtén los datos del PDF en formato blob.
      //const blob = await response.blob();

      // Crea una nueva URL para el blob.
    //  const blobURL = window.URL.createObjectURL(blob);

      // Crea un enlace (tag 'a') temporal, establece su href al blobURL y 'descarga' como atributo si se desea forzar la descarga.
    //  const tempLink = document.createElement('a');
   //   tempLink.href = blobURL;
   //   tempLink.setAttribute('download', 'filename.pdf'); // Descomenta esta línea si deseas que el archivo se descargue automáticamente en lugar de abrirse en una nueva pestaña del navegador.
   //   tempLink.target = '_blank'; // Para abrir el PDF en una nueva pestaña del navegador.

      // Adjunta el enlace al documento actual y haz clic en él.
    //  document.body.appendChild(tempLink);
    //  tempLink.click();

      // Limpiaza: elimina el enlace una vez que se haya hecho clic en él.
     // document.body.removeChild(tempLink);
    } catch (error) {
      console.error("Hubo un problema con la solicitud fetch:", error);
    }
  };

  render() {
    return (
      <button onClick={this.downloadPDF}>
        Descargar PDF
      </button>
    );
  }
}

export default DownloadPDFButton;
