import React from 'react';
import { Box } from '@mui/material';

// Components


const PrincipalCredencialLayout = ({ className, children }) => {

   return (
      <>
         <Box rol="main" component="main" className={`page-${className}`}>
            {children}
         </Box>
      </>
   )
}

export default PrincipalCredencialLayout;