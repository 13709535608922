import { GET_CREDENCIAL_QR } from 'constants/endpoints';

export default function getCredencialQr ({keyword = '1'} = {}) {
    //const apiURL = `https://localhost:7105/Credencial/React/${keyword}`
    //const apiURL = new URL(`https://www.cpbaonline.com.ar:8312/ReactQr/${keyword}`)
    //const apiURL = new URL(`https://www.cpbaonline.com.ar:8312/ReactQr/${keyword}`)
   // const apiURL = new URL(`https://calidadonline2:8311/ReactQr/${keyword}`)
    //console.log({keyword})
    //const params = {legajoEncriptadoQr: {keyword}.keyword}
  
  
    //apiURL.search = new URLSearchParams(params).toString();
  
    
    //apiURL.searchParams("legajoEncriptado", {keyword});
  
   // const apiURL = `http://calidadaps:3012/Credencial/React/${keyword}`
      const apiURL = new URL(GET_CREDENCIAL_QR+`/${keyword}`)
      return fetch(apiURL)
      .then(res => res.json())
      .then(response => {
        const {data} = response
        const images =  data
        //console.log(response)
        //console.log(images)
        return response
      })
  }