import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { CircularProgress} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams} from 'react-router-dom';
import { GET_LINK_ERROR } from 'constants/endpoints';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import getCredencialQr from 'services/getCredencialQr';
import Header from 'components/header';


const MiCredencialExterna = () => {
  let history = useHistory();
  const [image, setimages] = useState()

  const { imageId } = useParams();
  const urlError = GET_LINK_ERROR
  const floatingStyle = {
    position: 'center',
    margin:'4% 0 0 0',
    border: '5px solid #1E772D',
    borderRadius: '15px',
    padding: '40px',
    backgroundColor: 'white',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily:'Verdana',
    fontSize: 'xx-large',
    textAlign:'center',
    fontWeight: 'bold'
  };
  const linkStyle = {
    textDecoration: 'none',
    fontWeight: 'bold',
  };
  //let decodeCodlegajo = decodeURIComponent(imageId);
 
  useEffect(function () {
    getCredencialQr({keyword: imageId}).then(images => setimages(images))
  },[])

  if (image == undefined) {
   //return <div >${image.error}</div>;
   return <div style={{textAlign: "center"}}><div>Obteniendo Información</div><div><CircularProgress /></div></div>
  }
  else{
    if (!image.results) {
      if (image.iderror == 2 || image.iderror == 3 || image.iderror == 4){
        return <div style={floatingStyle}>
          <div>{image.error} <a style={linkStyle} href={urlError}>AQUI</a> - DENTRO DE LAS PRÓXIMAS 72 H HÁBILES PODRÁ VISUALIZAR SU CREDENCIAL</div>
          </div>;
          }
          else{
            return <div style={floatingStyle}>
            <div>{image.error} </div>
            </div>;
          }


     // return <div><Header/>
     // <div style={{textAlign: "center"}}>
      //  <div>{image.error} <a href="https://www.cpbaonline.com.ar:8105/editardatospersonales">AQUI</a></div>
     //   </div></div>;

        
     }  
    else
    {
      
      const imagenes = [
        image.data.frente,
        image.data.dorso
      ];
    
      return (
        <div style={{
          display: "flex",
          flex: "1"
          //border: "2px solid #000000"
          
          //padding: "40px 25px",
          //marginTop: "50px"
        }}>
          
          <div style={{
          display: "flex",
          flex: "1",
          alignItems: "center",
          justifyContent: "center",      //border: "2px solid #FF0000"
          //padding: "40px 25px",
          //marginTop: "50px"
        }}>

        
          <div style={{ padding:'10px 0px ', position: 'relative' , display:'flex', height:'100vh' ,maxHeight: '-webkit-fill-available'}}>
          <Carousel renderThumbs={() => {}} style={{ maxWidth: '100vw', height: '100vh'}}>
            <img src={imagenes[0]} alt="Imagen F" 
            style={{objectFit:'contain' , height:'80vh'}} 
            />
            <img src={imagenes[1]} alt="Imagen seleccionada" 
            style={{objectFit:'contain' , height:'80vh'}} 
            />

          </Carousel>
            </div>
    

          
          </div>
        </div>
      );
    }
  }
};

export default MiCredencialExterna;