import { GET_CREDENCIAL } from 'constants/endpoints';
const apikey = ''


export default function getCredencial ({keyword = '1'} = {}) {

  var encodeToken = encodeURIComponent(keyword);
 //console.log(encodeCodUsu)
  const apiURL = new URL(GET_CREDENCIAL+`?tk=${encodeToken}`)
  //console.log({keyword})
  //console.log(apiURL)
  //console.log(decodeURIComponent(apiURL))
    return fetch(apiURL)
    .then(res => res.json())
    .then(response => {
      const {data} = response
      const images =  data
     //console.log(response)
      //console.log(images)
      return response
    })
}