import { createTheme } from '@mui/material/styles';

const theme = createTheme({
   typography: {
      useNextVariants: true,
   },
   palette: {
      primary: {
         light: '#7986cb',
         main: '#3f51b5',
         dark: '#303f9f',
         contrastText: '#fff',
      },
      secondary: {
         light: '#ff4081',
         main: '#f50057',
         dark: '#c51162',
         contrastText: '#fff',
      },
      text: {
         primary: 'rgba(0, 0, 0, 0.87)',
         secondary: 'rgba(0, 0, 0, 0.54)',
         disabled: 'rgba(0, 0, 0, 0.38)',
         hint: 'rgba(0, 0, 0, 0.38)',
      },
      background: {
         paper: '#fff',
         default: '#f5f5f5'
      },
   },
   zIndex: {
      appBar: 1200,
      drawer: 1100,
   },
});

export default theme;
