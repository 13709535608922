import React, { useEffect, useState } from 'react';
import { Box, Button, Tooltip,CircularProgress} from '@mui/material';
import { location, useHistory, useParams, useLocation} from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import getCredencial from 'services/getCredencial';
import { GET_LINK_ERROR } from 'constants/endpoints';


 
const MiCredencialInternaAux = () => {
let history = useHistory();
  const [image, setimages] = useState()
  const [imagenSeleccionada, setImagenSeleccionada] = useState(0); // Inicia con la primera imagen
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let imageId = query.get('token');
  //const { imageId } = useParams();
  const urlError = GET_LINK_ERROR
  
  const floatingStyle = {
    position: 'center',
    margin:'4% 0 0 0',
    border: '5px solid #1E772D',
    borderRadius: '15px',
    padding: '40px',
    backgroundColor: 'white',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    textAlign: 'center',
    
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily:'Verdana',
    fontSize: 'large',
    textAlign:'center',
    fontWeight: 'bold'
  };
  const linkStyle = {
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  useEffect(function () {
    getCredencial({keyword: imageId}).then(images => setimages(images))
    //getCredencial({keyword: decodeCodUsu}).then(images => setimages(images))
  },[])
  
  if (image == undefined) {
    return <div style={{textAlign: "center", 
    fontFamily: "Verdana",
    fontSize: "larger"}}><div>Obteniendo Información</div><div><CircularProgress /></div></div>
     //return <div>Obteniendo Información</div>
  }else
  {
  
    if (!image.results) {
      
      //console.log(image.iderror)
      if (image.iderror == 2 || image.iderror == 3 || image.iderror == 4){
      return <div style={floatingStyle}>
        <div>{image.error} <a style={linkStyle} href={urlError}>AQUI</a> - DENTRO DE LAS PRÓXIMAS 72 H HÁBILES PODRÁ VISUALIZAR SU CREDENCIAL</div>
        </div>;
        }
        else{
          return <div style={floatingStyle}>
          <div>{image.error} </div>
          </div>;
        }
    }
    else
    {
        const imagenes = [
            image.data.frente,
            image.data.dorso
          ];

    
   // const imagenes = [
   //   image.frente,
   //   image.dorso
   // ];

   return (
    <div style={{
      display: "flex",
      flex: "1"
      //border: "2px solid #000000"
      
      //padding: "40px 25px",
      //marginTop: "50px"
    }}>
      
      <div style={{
      display: "flex",
      flex: "1",
      alignItems: "center",
      justifyContent: "center",      //border: "2px solid #FF0000"
      //padding: "40px 25px",
      //marginTop: "50px"
    }}>

    
      <div style={{ padding:'10px 0px ', position: 'relative' , display:'flex', height:'100vh' ,maxHeight: '-webkit-fill-available'}}>
      <Carousel renderThumbs={() => {}} style={{ maxWidth: '100vw', height: '100vh'}}>
        <img src={imagenes[0]} alt="Imagen seleccionada" 
        style={{objectFit:'contain' , height:'80vh'}} 
        />
        <img src={imagenes[1]} alt="Imagen seleccionada" 
        style={{objectFit:'contain' , height:'80vh'}} 
        />

      </Carousel>
        </div>


      
      </div>
    </div>
  );
  }
};
}

export default MiCredencialInternaAux;