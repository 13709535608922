import React, { useState } from 'react';
import { Alert, AlertTitle, Button, Box, Card, Checkbox, FormGroup, FormControlLabel, CardContent, CardMedia, 
  Container, Grid, MenuItem, Paper, Stack, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, TextField, Typography, OutlinedInput , Tooltip} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams} from 'react-router-dom';
import images from '../components/image';





const useStyles = makeStyles((theme) => ({
  errorMesage: {
    fontWeight: 'lighter',
    color: 'red',
  },
  img: {

  }, 
  buttons:{
    backgroundColor: '#1a4821',
    color: 'white'
}

}));



const ImagesComponent = () => {

  let history = useHistory();

  const { imageId } = useParams();
  const image = images.find(img => img.id === parseInt(imageId));

  if (!image) {
    return <div >Credencial no encontrada</div>;
  }
  else
  {
    
    const imagenes = [
      image.frente,
      image.dorso
    ];

    return (
      <div style={{
        textAlign: "center",
        maxWidth: "450px",
        margin: "-30px auto",
        border: "1px solid #e6e6e6",
        //padding: "40px 25px",
        //marginTop: "50px"
      }}>
          
        <br></br>
        <div>
            <div>
            <img width="100%" src={image.frente} alt="Base64" />
            </div>
            <div>
            <img width="100%" src={image.dorso} alt="Base64" />
            </div>
        </div>
   


    
      </div>
    );
  }
};

export default ImagesComponent;