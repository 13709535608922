import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

const PruebaConexion = () => {
  // Mantén el objeto de datos en el estado
  const [datos, setDatos] = useState(null);

  // Este es un ejemplo de una clave API. En la práctica, nunca debes almacenar claves API directamente en tu código.
  // Considera usar variables de entorno u otros métodos de configuración seguros.
  const API_KEY = 'tu-api-key-aqui';

  useEffect(() => {
    // Define la función que va a buscar los datos
    const obtenerDatos = async () => {
      try {
        // Configura tu URL de API y cualquier otro detalle de la solicitud
        const url = 'https://localhost:7105/Credencial/prueba2/123456';

        // Realiza la solicitud a la API
        const respuesta = await fetch(url, {
          method: 'GET', // o 'POST', 'PUT', etc., según sea necesario
          headers: {
            'X-Api-Key': API_KEY,
            // si es necesario, agrega otros encabezados aquí
            'Content-Type': 'application/json',
          },
        });

        // Si la respuesta no es exitosa, lanza un error
        if (!respuesta.ok) {
          throw new Error('Error al obtener datos');
        }

        // Convierte la respuesta en JSON (o el formato que devuelve la API)
        const resultado = await respuesta.json();

        // Actualiza el estado con los datos obtenidos
        setDatos(resultado);
      } catch (error) {
        // Maneja cualquier error que ocurra durante la solicitud
        console.error('Hubo un problema con la solicitud fetch: ', error);
      }
    };

    // Llama a la función obtenerDatos definida anteriormente
    obtenerDatos();
  }, []); // La dependencia vacía [] significa que este useEffect se ejecutará una vez, similar a componentDidMount

  // Renderiza tu componente basado en los datos recibidos
  if (!datos) {
    return <div>Cargando...</div>;
  }

  if(!datos.results) {
    return <div >Credencial no encontrada</div>;
  }

  return (
    
    <div style={{
      display: "flex",
      flex: "1"
      //border: "2px solid #000000"
      
      //padding: "40px 25px",
      //marginTop: "50px"
    }}>
 
      <div style={{
      display: "flex",
      flex: "1",
      alignItems: "center",
      justifyContent: "center",      //border: "2px solid #FF0000"
      //padding: "40px 25px",
      //marginTop: "50px"
    }}>

     
      <div style={{ padding:'10px 0px ', position: 'relative' , display:'flex', height:'100vh' ,maxHeight: '-webkit-fill-available'}}>
      <Carousel renderThumbs={() => {}} style={{ maxWidth: '100vw', height: '100vh'}}>
        <img src={datos.data.frente} alt="Imagen seleccionada" 
        style={{objectFit:'contain' , height:'80vh'}} 
        />
        <img src={datos.data.frente} alt="Imagen seleccionada" 
        style={{objectFit:'contain' , height:'80vh'}} 
        />

      </Carousel>
        </div>


      
      </div>
    </div>
  );
};

export default PruebaConexion;
