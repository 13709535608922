import React from 'react';
import { Box } from '@mui/material';

// Components
import Header from 'components/header';

const PrincipalLayout = ({ className, children }) => {

   return (
      <>
         <Header />
         <Box rol="main" component="main" className={`page-${className} main`}>
            {children}
         </Box>
      </>
   )
}

export default PrincipalLayout;
