export const HEADERS = {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Headers': 'Content-Type, Authorization',
	'Content-Type': "application/json; charset=utf-8",
	//apikey: process.env.REACT_APP_API_KEY_PUNTO_FARMA_BACK,
};

//export const HOST = "https://localhost:7291/api";
export const HOST = process.env.REACT_APP_OMDB_KEY;
export const HOST2 = process.env.REACT_APP_ERROR_LINK;

console.log({variableEntorno: process.env});

const MS_CONTROLLER = {
	FILTRO_COMITENTE: "Actuaciones/ObtenerActuacionesComitentes",
	POST_COMITENTES: "ActuacionComitentes/AsociarComitentesAUnaActuacion",
	FILTRO_CUIT: "Comitentes/ObtenerUnComitentePorActuacionYCUIT",
	GET_STRING_COMITENTE: "ActuacionComitentes/GenerarNuevoStringDeComitentes",
	POST_SEGURIDAD: "Entornos/EsUsuarioValido",
	GET_CREDENCIAL: "React",
	GET_CREDENCIAL_QR: "ReactQr",
	GET_LINK_ERROR:"apps/Matriculas/EditarDatosPerfil/EditarDatosDelPerfil",
	GET_LINK_PDF:"PdfCredencial"
}

const ENDPOINT ={
	//GET_FILTRO_COMITENTE:""
}

export const POST_SEGURIDAD_COMITENTES = `${HOST}/${MS_CONTROLLER.POST_SEGURIDAD}`;
export const GET_FILTRO_COMITENTE = `${HOST}/${MS_CONTROLLER.FILTRO_COMITENTE}`;
export const GET_MOSTRAR_STRING_COMITENTE = `${HOST}/${MS_CONTROLLER.GET_STRING_COMITENTE}`;
export const POST_COMITENTE = `${HOST}/${MS_CONTROLLER.POST_COMITENTES}`;
export const GET_FILTRO_CUIT = `${HOST}/${MS_CONTROLLER.FILTRO_CUIT}`;
export const GET_CREDENCIAL = `${HOST}/${MS_CONTROLLER.GET_CREDENCIAL}`;
export const GET_CREDENCIAL_QR = `${HOST}/${MS_CONTROLLER.GET_CREDENCIAL_QR}`;
export const GET_LINK_ERROR = `${HOST2}/${MS_CONTROLLER.GET_LINK_ERROR}`;
export const GET_LINK_PDF = `${HOST}/${MS_CONTROLLER.GET_LINK_PDF}`;

