import React from 'react';
import MiCredencialPrincipal from 'components/miCredencialPrincipal';


const CredencialConsejo = () => {
    return (
       <MiCredencialPrincipal />
    );
};

export default CredencialConsejo;