import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import MiCredencial from 'components/miCredencial';
import { useHistory, useParams} from 'react-router-dom';

function MiCredencialPrincipal() {

    //const image = images.find(img => img.id === parseInt(imageId));

  return (
    <div>
      <BrowserView>
        <MiCredencial />
      </BrowserView>
      <MobileView>
        <MiCredencial />
      </MobileView>
    </div>
  );
}

export default MiCredencialPrincipal;