import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { Alert, AlertTitle, Button, Box, Card, Checkbox, FormGroup, FormControlLabel, CardContent, CardMedia, 
  Container, Grid, MenuItem, Paper, Stack, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, TextField, Typography, OutlinedInput , Tooltip} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams} from 'react-router-dom';
import images from '../components/image';
import { useSwipeable } from 'react-swipeable';
import Modal from 'react-modal';




const useStyles = makeStyles((theme) => ({
  errorMesage: {
    fontWeight: 'lighter',
    color: 'red',
  },
  img: {

  }, 
  buttons:{
    backgroundColor: '#1a4821',
    color: 'white'
}

}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const MiCredencial = () => {
  let history = useHistory();
  const [imagenSeleccionada, setImagenSeleccionada] = useState(0); // Inicia con la primera imagen
  const [modalAbierto, setModalAbierto] = useState(false); // Controla si el modal está abierto o no


  const { imageId } = useParams();
  const image = images.find(img => img.id === parseInt(imageId));

  if (!image) {
    return <div >Credencial no encontrada</div>;
  }
  else
  {
    
    const imagenes = [
      image.frente,
      image.dorso
    ];

    const abrirModal = () => {
      setModalAbierto(true);
    };
  
    const cerrarModal = () => {
      setModalAbierto(false);
    };
  
    const siguienteImagen = () => {
      if (imagenSeleccionada < imagenes.length - 1) {
        setImagenSeleccionada(imagenSeleccionada + 1);
      }
    };
  
    const imagenAnterior = () => {
      if (imagenSeleccionada > 0) {
        setImagenSeleccionada(imagenSeleccionada - 1);
      }
    };

    const handleVerCredencial= (url) =>{
      history.push(url);
    }

    const ESFrente = () =>
    {
      if  (imagenSeleccionada = 1) {
        ESFrente = "disabled"
      }
    };

    return (
      <div style={{
        textAlign: "center",
        maxWidth: "450px",
        margin: "-50px auto",
        border: "1px solid #e6e6e6"
        
        //padding: "40px 25px",
        //marginTop: "50px"
      }}>
        
        <div style={{
        textAlign: "center",
        maxWidth: "450px",
        //margin: "auto",
       
        marginBottom: "50px"
        //padding: "40px 25px",
        //marginTop: "50px"
      }}>
          <img src={image.qr} alt="Base64" />
        </div>
        <div style={{
        textAlign: "center",
        maxWidth: "450px",
        margin: "0 auto",
        border: "1px solid #e6e6e6",
        //padding: "40px 25px",
        //marginTop: "50px"
      }}>
        <Button color="success" onClick={abrirModal} variant="contained" fullWidth >
        Ver credencial válida
        </Button>

        </div>

        <div style={{
        textAlign: "center",
        maxWidth: "450px",
        margin: "0 auto",
        border: "1px solid #e6e6e6"
        //padding: "40px 25px",
        //marginTop: "50px"
      }}>
          <Button color="success" onClick={() => handleVerCredencial(image.url)} variant="contained" fullWidth >
          Descargar
          </Button>
      <Modal 
        isOpen={modalAbierto}
        onRequestClose={cerrarModal}
        contentLabel="Galería de imágenes"
        style={customStyles}
        // ver las demas propiedades de modal
      >
        <useSwipeable onSwipedLeft={siguienteImagen} onSwipedRight={imagenAnterior}>
        <div className="modal-imagen" style={{ position: 'relative',margin: "0 auto", maxWidth: "450px" }}>
          <img src={imagenes[imagenSeleccionada]} alt="Imagen seleccionada" 
          style={{ width: '100%', height: 'auto' }} 
          />
          <Tooltip title="Cerrar" arrow>
          <Button onClick={cerrarModal} style={{ position: 'absolute', top: '-8%', right: '-4%'  }}>X</Button>
          </Tooltip>
          <Tooltip title="Frente" arrow>
            <Button onClick={imagenAnterior} style={{ position: 'absolute', top: '50%', left: '-4%', transform: 'translateY(-50%)' }}>←
          </Button>
          </Tooltip>
          <Tooltip title="Dorso" arrow>  
          <Button onClick={siguienteImagen} style={{ position: 'absolute', top: '50%', right: '-4%', transform: 'translateY(-50%)' }}>→</Button>
          </Tooltip>
          </div>
        </useSwipeable>
      </Modal>
        
        </div>
      </div>
    );
  }
};

export default MiCredencial;